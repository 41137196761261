<template>
  <div
    class="main-wrapper"
    v-loading="!loaded"
    element-loading-text="正在验证授权"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <keep-alive>
      <router-view v-if="isAuthored" />
    </keep-alive>
    <InputShareCodeDialog :uuid="uuid" ref="InputShareCodeDialog"></InputShareCodeDialog>
  </div>
</template>

<script>
import { checkShareCode, checkShareUrl } from "../../api/api";
import InputShareCodeDialog from "./components/compents/InputShareCodeDialog";
import { Dialog } from "vant";
import { LocalStorage } from "ttl-localstorage";

export default {
  name: "ResultShare",
  components: {
    InputShareCodeDialog,
  },
  props: {
    uuid: String,
  },
  data() {
    return {
      isAuthored: false,
      loaded: false,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi",
        },
      ],
    };
  },
  async mounted() {
    LocalStorage.runGarbageCollector();
    const isAccess = LocalStorage.get(`${this.$route.params.checkId}-${this.uuid}-access`);
    if (isAccess) {
      this.loaded = true;
      this.isAuthored = true;
    } else {
      const res = await checkShareUrl(this.$route.params.checkId);
      if (res.msgCode != 1) {
        await Dialog({ message: res.msgInfo });
        this.loaded = true;
      } else {
        this.loaded = true;
        this.isAuthored = false;
        this.$refs.InputShareCodeDialog.dialogVisible = true;
      }
    }
  },
  destroyed() {},
  methods: {},
  computed: {
    isMobileDevice() {
      return window.innerWidth < 600;
    },
    shareCode() {
      return localStorage.getItem("share-code");
    },
  },
};
</script>

<style lang="stylus" scoped>
.main-wrapper {
  min-height 100vh
}
</style>
